import {Component, Provide, Vue, Watch} from 'vue-property-decorator';
import {ProductList} from "@/interface/pagas";
import {GetCommodityReq} from "@/interface/res";
import {GetCommodityApi} from "@/network/modules";
@Component({
  components: {
  },
})
export default class Search extends Vue {
  @Provide() id:string = ''
  @Provide() keyWord:string = ''
  @Provide() listTotal:number = -1
  @Provide() loading:boolean = false

  @Provide() embroideryHomeParams:GetCommodityReq = {
    pageNum:1,
    pageSize:6,
    commoditySeries:1,
  }
  @Provide() embroideryHomeList:Array<ProductList> = []
  @Provide() embroideryHomeListTotal:number = 0
  @Provide() warmLifeParams:GetCommodityReq = {
    pageNum:1,
    pageSize:6,
    commoditySeries:0,
  }
  @Provide() warmLifeList:Array<ProductList> = []
  @Provide() warmLifeListTotal:number = 0


  @Watch('$route',{immediate:true, deep:false})
  routechange(to: any) {
    const _this:any = this;
    let parameter = to.query.s
    let id = to.query.i
    if(_this.$base.isNull(parameter))return;

    _this.keyWord = _this.$decryptBy(parameter)

    if(_this.$base.isNull(id))return;
    id = _this.$decryptBy(id)

    _this.embroideryHomeParams.pageNum = 1
    _this.embroideryHomeList = []
    _this.embroideryHomeListTotal = 0
    _this.warmLifeParams.pageNum = 1
    _this.warmLifeList = []
    _this.warmLifeListTotal = 0

    if(id == '-1'){
      _this.GetByPageFn(1)
      _this.GetByPageFn(0)
      return;
    }
    _this.GetByPageFn(parseInt(id))

  }

  created(){
    const _this:any = this;
  }

  mounted(){
    const _this:any = this;
  }


  /**
   * @Author HS
   * @Date 2021/7/8 5:19 下午
   * @Description: 产品类型
   * @Params: { number ： iType - true } [类型：0-小家碧玉 1-储绣家居]
   * @Return:
  */
  async GetByPageFn(iType:number){
    const _this:any = this;
    _this.loading = true
    let params:GetCommodityReq = {};
    switch (iType) {
      case 0:
        if(_this.warmLifeParams.pageNum == 1){
          _this.warmLifeListTotal = 0
          _this.warmLifeList = []
        }
        params = _this.warmLifeParams
        break;
      case 1:
        if(_this.embroideryHomeParams.pageNum == 1){
          _this.embroideryHomeListTotal = 0
          _this.embroideryHomeList = []
        }
        params = _this.embroideryHomeParams
        break;
    }
    params.searchName = _this.keyWord
    const res = await GetCommodityApi(params)
    let a = setTimeout(()=>{_this.loading = false;clearTimeout(a)},500)
    const { data, code, total, msg } = res
    if(code != 200 ){
      _this.$message.error(msg);
      return
    }
    switch (iType) {
      case 0:
        _this.warmLifeList = [..._this.warmLifeList,...data]
        _this.warmLifeListTotal = total
        break;
      case 1:
        _this.embroideryHomeList = [..._this.embroideryHomeList,...data]
        _this.embroideryHomeListTotal = total
        break;
    }
    _this.listTotal = Number.parseInt(_this.warmLifeListTotal) + Number.parseInt(_this.embroideryHomeListTotal)
  }

  /**
   * @Author HS
   * @Date 2021/7/8 6:09 下午
   * @Description: 点击查看更多
   * @Params: { number ： iType - true } [类型：0-小家碧玉 1-储绣家居]
   * @Return: null
   */
  loadingMoreFn(iType:number){
    const _this:any = this;
    switch (iType) {
      case 0:
        _this.warmLifeParams.pageNum++
        break;
      case 1:
        _this.embroideryHomeParams.pageNum++
        break;
    }
    _this.GetByPageFn(iType)
  }


  /**
   * @Author HS
   * @Date 2021/7/8 11:46 上午
   * @Description: 跳转到详情
   * @Params: { number ： id - true } [id]
   * @Params: { string ： iPath - true } [路径]
   * @Return: null
   */
  goDetails(id:number,iPath:string,type:any){
    const _this:any = this;
    const link = _this.$base.routeLinkSplice(_this.$IndexConfig[iPath].path,`type=${type}&i=`+id)
    _this.$base.goRouter(link)
  }

}
